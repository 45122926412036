var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import MuiInputLabel from '@mui/material/InputLabel';
import { Select as MuiSelect, useTheme, } from '@mui/material';
import { useCallback, useMemo, useState, } from 'react';
import styled from '@emotion/styled';
import { BodyMediumSemiBold } from '@src/components/styled/Typography';
import { ReactComponent as CaretDownIcon } from '@icons/wolfkit-light/caret-down-light.svg';
import Icon from '@shared/ui/icons/Icon';
import InputLabel from '@shared/ui/inputs/InputLabel';
import { getSelectMenuStyles, getSelectStyles } from './styles';
const SelectCaretDownIcon = ({ className = undefined }) => (_jsx(Icon, { className: className, size: 20, IconComponent: CaretDownIcon }));
const NotificationText = styled(BodyMediumSemiBold, { shouldForwardProp: propName => propName !== 'notificationStatus' })(props => ({
    color: props.notificationStatus === 'error' ?
        props.theme.palette.error.main : props.theme.palette.text.primary,
    fontSize: 14,
    marginTop: props.theme.spacing_sizes.xs,
    lineHeight: '18px',
}));
const SelectContainer = styled.div(props => ({
    width: '100%',
    '& label.MuiInputLabel-root': {
        position: 'relative',
        top: 0,
        left: 0,
        transform: 'none',
        marginBottom: props.hasLabel ?
            getSelectStyles(props.theme).variants[props.selectVariant].labelMarginBottom :
            0,
        fontWeight: 500,
        fontSize: getSelectStyles(props.theme).variants[props.selectVariant].labelFontSize,
        lineHeight: getSelectStyles(props.theme).variants[props.selectVariant].labelLineHeight,
        color: props.theme.palette.text.primary,
        display: 'flex',
        justifyContent: 'space-between',
    },
    '& label.MuiInputLabel-root.Mui-disabled': {
        position: 'relative',
        top: 0,
        left: 0,
        transform: 'none',
        marginBottom: props.hasLabel ?
            getSelectStyles(props.theme).variants[props.selectVariant].labelMarginBottom :
            0,
        fontWeight: 500,
        fontSize: getSelectStyles(props.theme).variants[props.selectVariant].labelFontSize,
        color: props.theme.palette.text.primary,
    },
}));
const MuiSelectStyled = styled(MuiSelect, {
    shouldForwardProp: (propName) => propName !== 'filled' &&
        propName !== 'selectVariant' &&
        propName !== 'notificationStatus',
})(props => ({
    width: '100%',
    backgroundColor: getSelectStyles(props.theme).variants[props.selectVariant].backgroundColor,
    '& .MuiSelect-select': {
        color: getSelectStyles(props.theme).variants[props.selectVariant].selectInnerColor,
    },
    '& .MuiSelect-select.Mui-disabled': {
        backgroundColor: getSelectStyles(props.theme).variants[props.selectVariant]
            .disabledBackgroundColor,
        WebkitTextFillColor: 'inherit',
    },
    '& .MuiSelect-icon': {
        top: 'calc(50% - 10px)',
        right: 12,
    },
    '& fieldset.MuiOutlinedInput-notchedOutline': {
        border: props.notificationStatus === 'error' ?
            getSelectStyles(props.theme).variants[props.selectVariant].borderError :
            getSelectStyles(props.theme).variants[props.selectVariant].border,
    },
    '&:hover': {
        '& fieldset.MuiOutlinedInput-notchedOutline': {
            border: props.notificationStatus === 'error' ?
                getSelectStyles(props.theme).variants[props.selectVariant].borderError :
                getSelectStyles(props.theme).variants[props.selectVariant].borderHover,
        },
    },
    '&.Mui-focused': {
        '& fieldset.MuiOutlinedInput-notchedOutline': {
            border: props.notificationStatus === 'error' ?
                getSelectStyles(props.theme).variants[props.selectVariant].borderError :
                getSelectStyles(props.theme).variants[props.selectVariant].borderActive,
            borderWidth: 1,
            boxShadow: 'unset',
        },
    },
}));
const MenuStyles = (theme, selectVariant, highlightSelected) => ({
    '& .MuiPaper-root': {
        marginTop: `${getSelectMenuStyles(theme).variants[selectVariant].menuMarginTop}`,
        border: getSelectMenuStyles(theme).variants[selectVariant].menuBorder,
        boxShadow: getSelectMenuStyles(theme).variants[selectVariant].menuBoxShadow,
        '& li.MuiMenuItem-root': {
            padding: getSelectMenuStyles(theme).variants[selectVariant].padding,
            borderLeft: getSelectMenuStyles(theme).variants[selectVariant].itemBorderLeft,
            '&:hover': {
                backgroundColor: getSelectMenuStyles(theme).variants[selectVariant]
                    .itemHoverBackground,
            },
            '&.Mui-selected:hover': {
                backgroundColor: getSelectMenuStyles(theme).variants[selectVariant]
                    .selectedItemHoverBackground,
            },
            '&:active': {
                backgroundColor: getSelectMenuStyles(theme).variants[selectVariant]
                    .itemActiveBackground,
            },
            '&.Mui-selected': {
                backgroundColor: highlightSelected ?
                    getSelectMenuStyles(theme)
                        .variants[selectVariant]
                        .selectedItemBackgroundHighlited :
                    getSelectMenuStyles(theme)
                        .variants[selectVariant]
                        .itemActiveBackground,
                borderLeft: getSelectMenuStyles(theme).variants[selectVariant]
                    .selectedItemBorderLeft,
            },
        },
    },
});
// eslint-disable-next-line react/function-component-definition
function Select(_a) {
    var { size = 'small', selectVariant = 'outlined', highlightSelected = false, label = undefined, onBlur = undefined, required = undefined, disabled = undefined, notification = undefined, notificationStatus = undefined } = _a, props = __rest(_a, ["size", "selectVariant", "highlightSelected", "label", "onBlur", "required", "disabled", "notification", "notificationStatus"]);
    const theme = useTheme();
    const [displayNode, setDisplayNode] = useState(null);
    const [focused, setIsFocused] = useState(false);
    const handleBlur = (ev) => {
        setIsFocused(false);
        if (onBlur) {
            onBlur(ev);
        }
    };
    const handleFocus = () => {
        setIsFocused(true);
    };
    // this hack is equal for select in mui
    const getSelectRef = useCallback((node) => {
        if (node) {
            setDisplayNode(node);
        }
    }, []);
    const menuWidth = useMemo(() => {
        if (displayNode) {
            return displayNode.clientWidth;
        }
        return undefined;
    }, [displayNode]);
    return (_jsxs(SelectContainer, { selectVariant: selectVariant, hasLabel: Boolean(typeof label !== 'undefined'), children: [label ? (_jsx(MuiInputLabel, { disabled: disabled, shrink: true, children: _jsx(InputLabel, { focused: focused, label: label, size: size, required: required }) })) : undefined, _jsx(MuiSelectStyled, Object.assign({}, props, { ref: getSelectRef, size: size, selectVariant: selectVariant, variant: 'outlined', IconComponent: SelectCaretDownIcon, MenuProps: Object.assign(Object.assign({}, (props.MenuProps || {})), { sx: Object.assign(Object.assign({}, MenuStyles(theme, selectVariant, highlightSelected)), { maxWidth: menuWidth, maxHeight: displayNode ? `calc(100% - ${displayNode.getBoundingClientRect().bottom}px)` : undefined }) }), error: notificationStatus === 'error', onBlur: handleBlur, onFocus: handleFocus, disabled: disabled })), (typeof notification !== 'undefined') && (_jsx(NotificationText, { notificationStatus: notificationStatus, children: notification }))] }));
}
export default Select;
